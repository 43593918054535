<template lang='pug'>
  extends ../templates/PageBase.pug
  block content
    .container-fluid.container-fixed-lg
      .row
        .col-lg-12
          .card.card-default
            .card-header
              .card-title
                | Input
            .card-body
              div(role='form')
                .row
                  .col-md-4
                    .form-group.form-group-default.required
                      label Note Title
                      input.form-control(type='text', required='' v-model='title')
                  .col-md-4
                    .form-group.form-group-default.form-group-default-select2.required
                      label Language
                      select.full-width(data-placeholder='Select Language' ref="select_lang")
                        option(:value='data[1]' v-for='data in lexers') {{ data[0] }}
                  .col-md-4
                    .form-group.form-group-default
                      label Author
                      input.form-control(type='text' v-model='author')

                .form-group
                  div.editor(ref='editorResult')
                .row
                  .col-md-4
                    .form-group
                      button.btn.btn-primary.btn-cons.m-t-20(@click.prevent='downloadResult' :disabled='isLoading') Download Evernote Export-File
                  .col-md-8
                    .progress-circle-indeterminate(style='margin-left: 0; margin-top: 10px' v-if='isLoading')

</template>

<script>
  import ace from 'ace-builds/src-noconflict/ace'
  import theme from 'ace-builds/src-noconflict/theme-xcode'
  import axios from 'axios'
  import lexers from '../assets/planguages'
  import FileSaver from 'file-saver'

  const CDN = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.1/src-min-noconflict'
  ace.config.set('basePath', CDN)
  ace.config.set('modePath', CDN)
  ace.config.set('themePath', CDN)
  ace.config.set('workerPath', CDN)

  export default {
    name: 'EverCode',
    mounted() {
      const self = this

      this.editor = ace.edit(this.$refs.editorResult)
      this.editor.setTheme(theme)
      this.editor.session.setMode('ace/mode/python')

      // Select 2 - Language
      window.$(this.$refs.select_lang).select2({
        minimumResultsForSearch: (window.$(this).attr('data-disable-search') == 'true' ? -1 : 1)
      }).on('select2:open', function () {
        window.$.fn.scrollbar && window.$('.select2-results__options').scrollbar({
          ignoreMobile: false
        })
      }).on('select2:select', function (e) {
        [self.serverLang, self.language] = e.params.data.id.split(',')
        self.$ga.event('evercode', 'action', 'select-language', self.language)
        self.editor.session.setMode(`ace/mode/${self.language}`)
      })
    },
    data() {
      return {
        lexers: lexers,
        language: null,
        serverLang: null,
        title: '',
        author: '',
        isLoading: false
      }
    },
    methods: {
      validateForm() {
        let error = ''

        if (!this.editor.getValue()) {
          error = 'Please provide some code!'
        }
        if (!this.serverLang) {
          error = 'Please select a language!'
        }
        if (!this.title) {
          error = 'Please enter a Note title!'
        }

        if (error !== '') {
          window.$('.page-content-wrapper').pgNotification({
            style: 'flip',
            message: error,
            position: 'top-right',
            timeout: 3000,
            type: 'danger'
          }).show()
        }

        return error === ''
      },
      downloadResult() {
        const self = this

        if (!this.validateForm()) {
          return
        }

        this.isLoading = true

        const data = {
          code: this.editor.getValue(),
          title: this.title,
          language: this.serverLang,
          author: this.author
        }

        axios.post(
          'https://hm9lx7e7nb.execute-api.eu-central-1.amazonaws.com/api/evernote',
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        ).then(function (resp) {
          const blob = new Blob([resp.data], {type: 'application/json;charset=utf-8'})
          const fName = `${self.title}.enex`
          FileSaver.saveAs(blob, fName)
          self.isLoading = false

          window.$('.page-content-wrapper').pgNotification({
            style: 'flip',
            message: 'Download successfull!',
            position: 'top-right',
            timeout: 3000,
            type: 'success'
          }).show()

        }).catch(function () {
          self.isLoading = false
        })

        this.$ga.event({eventCategory: 'editor', eventAction: 'download', eventLabel: 'note'})
      }
    }
  }
</script>

<style scoped>
  .editor, .example-editor {
    height: 500px;
    width: 100%;
  }

  .example-editor {
    height: 510px;
  }
</style>
